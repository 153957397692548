<template>
  <div class="home">
    <el-container>
      <el-header height="200" style="padding: 0;">
        <Nav :showSearch="false"/>
      </el-header>
      <div class="wrapper">
        <div id="building"
             style="align-self:center; text-align: center; width: 680px ;border-radius: 10px;padding-top:30px">

          <div>

          </div>
          <div style="padding:0px 47px 25px 47px; text-align: center">
            <el-form ref="loginFormRef" :model="form" :rules="rules">
              <el-tabs >
                <el-tab-pane label="重置密码">
                  <div class="container">


                    <el-form-item prop="phone" label="手机号码" label-width="80px" >
                      <el-input v-model="form.phone" placeholder="请输入手机号码"  ref="phone"  >

                      </el-input>
                    </el-form-item>
                    <el-form-item prop="verifyCode" label="短信验证" label-width="80px" >
                      <el-input v-model="form.verifyCode" placeholder="请输入短信验证"   >
                        <el-button slot="append" type="primary"  class="verify_button"  @click="send_code"  >{{form.verify_button_text}}</el-button>
                      </el-input>
                    </el-form-item>
                    <el-form-item prop="password" label="新密码" label-width="80px" >
                      <el-input v-model="form.password" placeholder="请输登录密码"  show-password  >
                      </el-input>
                    </el-form-item>
                    <el-form-item prop="confirm_password" label="确认密码" label-width="80px" >
                      <el-input v-model="form.confirm_password" show-password  placeholder="请再次输入确认密码"   >
                      </el-input>
                    </el-form-item>

                    <el-form-item>
                      <el-button  style="width: 100%;margin-top:10px;" type="primary"
                                  @click="doLogin">重 置
                      </el-button>
                    </el-form-item>
                  </div>
                </el-tab-pane>

              </el-tabs>
            </el-form>

          </div>
          <Verify ref="verify" :captchaType="'blockPuzzle'" :imgSize="{ width: '300px', height: '155px' }"
                  :mode="'pop'"
                  @success="doLogin"
          />
        </div>
      </div>
      <div style="position:absolute;bottom: 0px; width: 100%; ">
        <footer2/>
      </div>
    </el-container>
  </div>
</template>
<script setup>
import footer2 from "components/footer.vue";
import Nav from "@/components/nav";
import {reactive, ref} from "vue";
import Verify from "components/verifition/Verify.vue";
import i_code from "@/assets/img/icon-code.png";
import i_username from "@/assets/img/icon-username.png";
import i_password from "@/assets/img/icon-password.png";
import i_tel from "@/assets/img/icon-tel.png";
import {Message} from "element-ui";
import CommonService from "@/service/CommonService";
import router from "@/router";

const verify = ref();
const loginFormRef = ref();
const form = reactive({
  password: "",
  verifyCode: "",
  phone: "",
  confirm_password: "",
  verify_button_text: "发送验证码",
  number: 60

})



const send_code = function ()  {
  if(form.phone=='')
  {
    Message.error("请输入手机号");
  }else
  {
    if(form.number==60) {
      CommonService.send_verify_code(form.phone).then((res) => {
        if (res.code == 200  || res.code == 0) {
          Message.success("发送成功");
        }else
        {
          Message.success(res.msg);
        }
      })
    }
    Timeout();
  }

};

const Timeout=function (){
  if(form.number==60)
  {
    var times=setInterval(function() {
      form.number= form.number-1;
      form.verify_button_text=form.number;
      if(form.number<=0)
      { form.number=60;
        form.verify_button_text="发送验证码";
        clearInterval(times);
      }
      // 需要执行的代码
    }, 1000);
  }

}


const doLogin = async () => {
  const valid = await loginFormRef.value.validate().catch(() => {
  });
  if (!valid) return false;

  if(form.password!=form.confirm_password)
  {
    Message.error("两次密码输入不一致！");
    return
  }

  CommonService.setUserPassword(form.password,form.phone,form.verifyCode).then((res) => {
    // console.log("111");
    if (res.code === 200) {
      Message.success("修改成功！")
      setTimeout(() => {
        router.push({path: "/login"});
      }, 1000);
    }else {
      Message.error(res.msg);
    }
  })
};

const rules = {
  password: [
    {required: true, message: "请输入用户名", trigger: "blur"}
  ],
  username: [
    {required: true, message: "请输入姓名", trigger: "blur"}
  ],verifyCode: [
    {required: true, message: "请输入验证码", trigger: "blur"}
  ],
  phone: [
    {required: true, message: "请输入手机号", trigger: "blur"},
    { type: 'number',
      message: '手机号格式不正确',
      trigger: 'blur',
      transform(value) {
        var phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
        if (!phonereg.test(value)) {
          return false
        } else {
          return Number(value)
        }
      }
    }
  ],confirm_password: [
    {required: true, message: "请输入确认密码", trigger: "blur"}
  ]
};
</script>

<style lang="scss" scoped>

.wrapper {
  /*渐变的背景色*/
  /*height: 100vh;
  background-image: linear-gradient(to bottom right, #FC466B, #3F5EF8);
  overflow: hidden;*/
  /*背景图*/
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}
.title {
  text-align: center;
  font-size: 25px;
  color: #3f3f3f;
  margin-bottom: 10px;
  span{
    z-index: 99;padding: 0px 10px; background: whitesmoke;
  }
  hr{
    margin-top: -15px; border: 1px solid black;
  }
}
::v-deep .el-step__head.is-process {
  color: #2b70c2;
  border-color: #2b70c2;
}
::v-deep .el-step__title.is-process {
  color: #2b70c2;
}

.title {
  padding: 55px 0px 20px 0px;
  text-align: center;
  font-size: 20px;
  background: #f7f8fa;
  border-radius: 10px
}

::v-deep .el-link {
  font-size: 16px;
}

::v-deep .el-form-item {
  margin-bottom: 30px;
}
::v-deep .el-tabs__item
{
  font-size: 18px;
  font-weight: 100;
}
::v-deep .el-input__inner {
  height: 45px;
  font-size: 16px;
}

.header-icon {
  margin-top: 12px;
  width: 18px;
  height: 18px;
}
</style>
